import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

interface OpcionMenu {
  ruta: string;
  texto: string;
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})


export class NavbarComponent implements OnInit{
 
  // public mostrarCamping:boolean = false;

  get mostrarAlmacen(){
    return environment.moduloAlmacen;
  }

  get mostrarBar(){
    return environment.moduloBar;
  }

  get mostrarCaja(){
    return environment.moduloCaja;
  }

  get mostrarCaja2(){
    return environment.moduloCaja2;
  }

  get mostrarCamping(){
    return environment.moduloCamping;
  }

  get mostrarPeluqueria(){
    return environment.moduloPeluqueria;
  }

  get mostrarTaller(){
    return environment.moduloTaller;
  }

  get mostrarTienda(){
    return environment.moduloTienda;
  }

  get mostrarVentas(){
    return environment.moduloVentas;
  }



  public menuMantenimientos:OpcionMenu[] = [
    {ruta: '/mantenimientos/manArticulos/0', texto: 'Mantenimiento Artículos'},
    {ruta: '/mantenimientos/consultaArticulos', texto: 'Consulta Artículos'},
    {ruta: '/mantenimientos/manClientes/0', texto: 'Mantenimiento Clientes'},
    {ruta: '/mantenimientos/consultaClientes', texto: 'Consulta Clientes'},
    {ruta: '/mantenimientos/manFamilias/0', texto: 'Mantenimiento Familias'},
    {ruta: '/mantenimientos/manSubFamilias/0', texto: 'Mantenimiento SubFamilias'},
    {ruta: '/mantenimientos/consultaFamilias', texto: 'Consulta Familias'},
    {ruta: '/mantenimientos/consultaSubFamilias', texto: 'Consulta SubFamilias'},
    {ruta: '/mantenimientos/manProveedores/0', texto: 'Mantenimiento Proveedores'},
    {ruta: '/mantenimientos/consultaProveedores', texto: 'Consulta Proveedores'},
    {ruta: '/mantenimientos/manUsuarios/0', texto: 'Mantenimiento Usuarios'},
  ];

  public dividersMantenimientos: number[] = [
    1,3,7,9,
  ];

  public menuCamping:OpcionMenu[] = [
    {ruta: '/camping/temporadaAlta', texto: 'Temporada Alta'},
    {ruta: '/camping/manParcelas/0', texto: 'Mantenimiento Parcelas'},
    {ruta: '/camping/confParcelas', texto: 'Configuración Parcelas'},
    {ruta: '/camping/parcelas', texto: 'Gestión Parcelas'},
    {ruta: '/camping/reservaParcelas', texto: 'Reserva Parcelas'},
    {ruta: '/camping/listadoReservas', texto: 'Listado Reservas'},
    // {ruta: '/utilidades/confTactil/tienda', texto: 'Configuración Tienda'},
    // {ruta: '/ventas/tactilTickets/0/tienda', texto: 'Tienda'},
    {ruta: '/camping/consultaOcupacion', texto: 'Estadística de Ocupación'},
    {ruta: '/camping/partesEntrada', texto: 'Exportar a Hospederías'},
    {ruta: '/utilidades/confTactil/camping', texto: 'Configuración Extras'},

  ]

  public dividersCamping: number[] = [
    3,5,7,9
  ]

  public menuTienda:OpcionMenu[] = [
    {ruta: '/caja/apertura/3', texto: 'Apertura de Caja'},
    {ruta: '/caja/arqueo/0/3', texto: 'Arqueo de Caja'},
    {ruta: '/utilidades/confTactil/tienda', texto: 'Configuración Tienda'},
    {ruta: '/ventas/tactilTickets/0/tienda', texto: 'Tienda'},

  ]

  public dividersTienda: number[] = [
    1
  ]

  public menuBar:OpcionMenu[] = [
    {ruta: '/ventas/mesas', texto: 'Mesas'},
    {ruta: '/ventas/tactilTickets/0/bar', texto: 'Bar'},
    {ruta: '/ventas/consultaFacturas', texto: 'Consulta de Tickets'},
    {ruta: '/utilidades/reserva', texto: 'Reserva de Mesas'},
    {ruta: '/utilidades/confMesas', texto: 'Configuración de Mesas'},
    {ruta: '/utilidades/confTactil/bar', texto: 'Configuración Bar'},
    {ruta: '/reports/bar/precios', texto: 'Listado Precios'},

  ]

  public dividersBar: number[] = [
    1,3,5
  ]

  public menuTaller:OpcionMenu[] = [
    {ruta: '/mantenimientos/manVehiculos/0', texto: 'Mantenimiento Vehículos'},
    {ruta: '/taller/ordenes/0', texto: 'Órdenes de Trabajo'},
    {ruta: '/taller/consultaOrdenes', texto: 'Consulta Ordenes'},
    {ruta: '/ventas/albaranes/0', texto: 'Albaranes'},
    {ruta: '/ventas/consultaAlbaranes', texto: 'Consulta Albaranes'},
    {ruta: '/ventas/facturacionAlbaranes', texto: 'Facturación de Albaranes'},
    {ruta: '/ventas/facturas/0', texto: 'Facturas'},
    {ruta: '/ventas/consultaFacturas', texto: 'Consulta de Facturas'},
    {ruta: '/ventas/presupuestos/0', texto: 'Presupuestos'},
    {ruta: '/ventas/consultaPresupuestos', texto: 'Consulta Presupuestos'},
  ];

  public dividersTaller: number[] = [
    0,2,5,7
  ];

  public menuPeluqueria:OpcionMenu[] = [
    {ruta: '/ventas/tactilTickets/0/peluqueria', texto: 'Tickets de Venta'},
    {ruta: '/ventas/consultaFacturas', texto: 'Consulta de Tickets'},
    {ruta: '/peluqueria/marcas', texto: 'Marcas Color'},
    {ruta: '/peluqueria/trabajos', texto: 'Tipos Trabajo'},
    {ruta: '/peluqueria/estilistas', texto: 'Estilistas'},
    {ruta: '/utilidades/confTactil/peluqueria', texto: 'Configuración Peluquería'},
    {ruta: '/peluqueria/agenda', texto: 'Agenda'},
  ];

  public dividersPeluqueria: number[] = [
    1,4
  ];

  public menuAlmacen:OpcionMenu[] = [
    {ruta: '/almacen/albaranEntrada/0', texto: 'Entrada de Mercancía'},
    {ruta: '/almacen/consultaEntradas', texto: 'Consulta de Entradas'},
    {ruta: '/almacen/mercanciaAlmacen', texto: 'Mercancía en Almacén'},
    {ruta: '/almacen/ajuste', texto: 'Ajuste de Almacén'},
    {ruta: '/almacen/consultaStockBajo', texto: 'Artículos Bajo Stock Mínimo'},
  ];

  public dividersAlmacen: number[] = [
    
  ];

  public menuCaja:OpcionMenu[] = [
    {ruta: '/caja/apertura/1', texto: 'Apertura de Caja'},
    {ruta: '/caja/arqueo/0/1', texto: 'Arqueo de Caja'},
    {ruta: '/caja/consultaCajas', texto: 'Consulta de Arqueos'},
    {ruta: '/caja/gastosCaja/1', texto: 'Gastos Caja'},
    {ruta: '/caja/desgloseCobros', texto: 'Desglose Cobro'},
  ];

  public dividersCaja: number[] = [
    
  ];

  public menuCaja2:OpcionMenu[] = [
    {ruta: '/caja/apertura/2', texto: 'Apertura de Caja'},
    {ruta: '/caja/arqueo/0/2', texto: 'Arqueo de Caja'},
    {ruta: '/caja/consultaCajas', texto: 'Consulta de Arqueos'},
    {ruta: '/caja/gastosCaja/2', texto: 'Gastos Caja'},
    {ruta: '/caja/desgloseCobros', texto: 'Desglose Cobro'},

  ];

  public dividersCaja2: number[] = [
    
  ];

  public menuVentas:OpcionMenu[] = [
    {ruta: '/ventas/albaranes/0', texto: 'Albaranes'},
    {ruta: '/ventas/consultaAlbaranes', texto: 'Consulta Albaranes'},
    {ruta: '/ventas/facturacionAlbaranes', texto: 'Facturación de Albaranes'},
    {ruta: '/ventas/facturas/0', texto: 'Facturas'},
    {ruta: '/ventas/consultaFacturas', texto: 'Consulta de Facturas'},
    {ruta: '/ventas/periodicas/0', texto: 'Facturas Periódicas'},
    {ruta: '/ventas/consultaPeriodicas', texto: 'Consulta Facturas Periódicas'},
    {ruta: '/ventas/facturacionesPeriodicas', texto: 'Facturar Periódicas'},
    // {ruta: '/ventas/mesas', texto: 'Gestión de Mesas'},
    {ruta: '/ventas/tactilTickets/0/bar', texto: 'Tickets de Venta'},
    {ruta: '/ventas/presupuestos/0', texto: 'Presupuestos'},
    {ruta: '/ventas/consultaPresupuestos', texto: 'Consulta Presupuestos'},
    // {ruta: '/ventas/devoluciones/0', texto: 'Devoluciones'},
    {ruta: '/ventas/remesas', texto: 'Remesas'},
    {ruta: '/ventas/descuentosVolumen/0', texto: 'Descuentos por Volumen'},

  ];

  public dividersVentas: number[] = [
    1,2,4,7,8,10
  ];

  public menuInformes:OpcionMenu[] = [
    {ruta: '/informes/comprasMensual', texto: 'Resumen de Compras Mensual'},
    {ruta: '/informes/ventasMensual', texto: 'Resumen de Ventas Mensual'},
    {ruta: '/informes/ventasArticulo', texto: 'Ventas por Artículo'},
    {ruta: '/informes/ventasCliente', texto: 'Ventas por Cliente'},
    {ruta: '/informes/efectivoDia', texto: 'Ventas Efectivo Día'},
    {ruta: '/informes/comprasProveedor', texto: 'Compras por Proveedor'},
    {ruta: '/informes/gventasAnual', texto: 'Gráfica de Ventas Anual'},
    {ruta: '/informes/gventasFamilia', texto: 'Gráfica de Ventas por Familia'},
    {ruta: '/informes/modelo347', texto: 'Modelo 347'},
  ];

  public dividersInformes: number[] = [
    
  ];

  public menuUtilidades:OpcionMenu[] = [
    // {ruta: '/utilidades/login', texto: 'Login'},
    {ruta: 'login', texto: 'Login'},
    {ruta: '/utilidades/busquedaGlobal', texto: 'Buscador'},
    // {ruta: '/utilidades/logout', texto: 'Logout'},
    {ruta: '/utilidades/galeria', texto: 'Galería de Imágenes'},
    {ruta: '/utilidades/upload', texto: 'Subir Imágenes'},
    // {ruta: '/utilidades/reserva', texto: 'Reserva de Mesas'},
    // {ruta: '/utilidades/confMesas', texto: 'Configuración de Mesas'},
    {ruta: '/utilidades/confTactil/bar', texto: 'Configuración Táctil'},
    {ruta: '/utilidades/fichaje', texto: 'Registro actividad'},
    {ruta: '/utilidades/consultaFichadas', texto: 'Consulta Fichadas'},
    // {ruta: '/utilidades/lectorDocumentos', texto: 'Lector Documentos'},
    {ruta: '/utilidades/preferencias', texto: 'Preferencias'},
    {ruta: '/utilidades/ayuda', texto: 'Ayuda'},
    {ruta: '/utilidades/desarrollo', texto: 'Opcs. Desarrollo'},
  ];

  public dividersUtilidades: number[] = [
    0,3,5,6,7
  ];
  
  constructor(private route:Router) { }

  ngOnInit(): void {
  }

}
