import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Articulo } from '../interfaces/articulo.interface';

const base_url = `${ environment.base_url }/Articulos`;


@Injectable({
  providedIn: 'root'
})
export class ArticulosService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });


  constructor(private http: HttpClient) { }

  getAll(pagina:number, rows:number, desdeCodigo:string, hastaCodigo:string, desdeDescripcion:string, hastaDescripcion:string, desdeDescripcionCorta:string, hastaDescripcionCorta:string, desdeTipo:string, hastaTipo:string, bloqueados:boolean, orden:string, desc:number){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&deart=${desdeCodigo}&haart=${hastaCodigo}&dedes=${desdeDescripcion
                  }&hades=${hastaDescripcion}&decor=${desdeDescripcionCorta}&hacor=${hastaDescripcionCorta}&bloq=${bloqueados}&detip=${desdeTipo
                  }&hatip=${hastaTipo}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getArticuloId(id:number){
    const url = `${ base_url }/getArticuloId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getArticuloBarrasoCodigo(codigo:string){
    const url = `${ base_url }/getArticuloBarrasoCodigo/${codigo}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getArticuloCodigo(codigo:string){
    const url = `${ base_url }/getArticuloCodigo/${codigo}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizar(data:string, id:number){
    const url = `${ base_url }/putArticulo/${id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  postArticuloJson(data:Articulo){
    const url = `${ base_url }/postArticuloJson`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, ultimoId: resp.totalPaginas};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevo(data:string){
    const url = `${ base_url }/postArticulo`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle, ultimoId: resp.totalPaginas };
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busqueda(valor:string, pagina:number, rows:number){
    const url = `${ base_url }/getBusquedaArticulo/${valor}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getCombo(orden:string, controlStock: boolean = false){
    const url = `${ base_url }/getAll?&order=${orden}&stoc=${controlStock}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getArticulosCamping(){
    const url = `${ base_url }/getArticulosCamping`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getArticulosBraisChas(){
    const url = `http://localhost/apiBraisChas/Articulos.php`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  grabarArticulos(data:Articulo[]){
    const url = `${ base_url }/postArticulos`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  grabarArticulosBraisChas(data:Articulo[]){
    const url = `${ environment.base_url }/ArticulosBraisChas/postArticulos`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  modificarArticulosBraisChas(data:Articulo[]){
    const url = `${ environment.base_url }/ArticulosBraisChas/putArticulos`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarPrecioCoste(idArticulo: number, precioCoste: number){
    const url = `${ base_url }/actualizarPrecioCoste/${idArticulo}/${precioCoste}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
  
}
