<a class="btn btn-primary centroIzquierda-flotante d-print-none" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
       <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
   </svg>
 </a>
 

 <div class="offcanvas offcanvas-start  bordeContenedor botones d-print-none" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
   <div class="offcanvas-header">
     <h5 class="offcanvas-title" id="offcanvasExampleLabel">Abrir Nueva Pestaña</h5>
     <div class="x">
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
     </div>
     <br>
   </div>

   <div class="offcanvas-body fondoFormulario bordeContenedor d-print-none">
         <div *ngFor="let boton of botonesFuncion; let i=index" class="text-center">
            <div>
              <img (contextmenu)="clickBotonDFuncion(i);"  (click)="clickBotonFuncion(i);" src="{{boton.imagen}}" class="imagen bordeContenedor clickable">
            </div>
            <!-- <button *ngIf="boton.codigoFuncion" (click)="clickBotonFuncion(i);" type="button" class="btn btn-warning textoCentrado bordeContenedor botonMenu">
              <img (click)="clickBotonFuncion(i);" src="{{boton.imagen}}" class="imagen bordeContenedor">
            </button> -->
          </div>
   </div>
 </div>
