<!-- <div class="container mt-3 d-print-none "> -->
<div class="buscador fade-in-image">
    <div class="row">
        <div class="col-lg-11"></div>

        <button (click)="cancelar();" type="button" class="btn col-lg-1">
                    
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
              </svg>
            
            </button>

    </div>
    <div class="row mb-2">
        <div class="col-lg-1"></div>
        <div class="col-lg-8">
            <h2>Enviar Email</h2>
        </div>
    </div>
    <form autocomplete="off" [formGroup]="formulario" (ngSubmit)="enviar()">
        <div class="form-group row mt-4">
            <label class="col-lg-3 col-md-2 col-form-label text-end">Para</label>
            <div class="col-lg-3">
                <input mTab class="form-control" id=correo type="text" formControlName="correo">
                <small *ngIf="campoNoValido('correo')" class="form-text text-danger">Debe ser un correo válido</small>
            </div>
        </div>
        <div class="form-group row mt-4">
            <label class="col-lg-3 col-md-2 col-form-label text-end">Asunto</label>
            <div class="col-lg-4">
                <input mTab class="form-control" id=asunto type="text" formControlName="asunto">
                <small *ngIf="campoNoValido('correo')" class="form-text text-danger">Debe tener al menos 2 caracteres</small>
            </div>
        </div>
        <div class="form-group row mt-4">
            <label class="col-lg-3 col-md-2 col-form-label text-end">Mensaje</label>
            <div class="col-lg-4">
                <textarea mTab class="form-control" id="mensaje" rows="2" formControlName="mensaje"></textarea>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-3"></div>
                <button (click)="enviar()" type="button" class="btn btn-primary btn-block text-center col-lg-2 col-md-4 mt-3">Enviar</button>
        </div>

    </form>
</div>

