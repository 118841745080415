// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // base_url: 'http://tpv-rf.com',
  // base_url: 'http://localhost/tpv-rf/public',
  // base_url: 'http://gestionangular.com/tpv-rf/public',
  // base_url: 'http://localhost/ddtpv-rf/public',
  base_url: 'http://gestionangular.es/tpv/public',
  // base_url: 'https://desarrollo.gestionangular.es/tpv/public',
  // base_url: 'http://minas/tpv-rf/public',


  /* -------------------------------------------------------------------------- */
  /*                             BASES    CLIENTES                              */
  /* -------------------------------------------------------------------------- */
  // base_url: 'https://braischas.gestionangular.es/tpv/public',
  // base_url: 'https://capricho.gestionangular.es/tpv/public',
  // base_url: 'https://evo.gestionangular.es/tpv/public',
  // base_url: 'https://metalrod.gestionangular.es/tpv/public',
  // base_url: 'http://nevedas.gestionangular.es/tpv/public',
  // base_url: 'https://silva.gestionangular.es/tpv/public',
  // base_url: 'http://valino.gestionangular.es/tpv/public',


  marcaFinal: 'final', //Es la palabra clave para que codIgniter ignore los parámetros 'hasta'. Lógicamente también debe estar definida como constante en codeIgniter.
  clientes: [],
  articulos: [],
  noImage: 'assets/noimage.png',
  almacenDefecto: 1,
  tarifaDefecto: 1,
  timeOutDefecto: 2000,
  activarVoz: false,
  gmt: "",


  /* -------------------------------------------------------------------------- */
  /*                               CONFIGURACIONES                              */
  /* -------------------------------------------------------------------------- */

  activarAvisoFacturasPeriodicas: false,
  activarAvisoStockMinimo: false,
  activarFraseLogin: false,
  loginHastaDomingo: false,

  /* -------------------------------------------------------------------------- */
  /*                                  ARTICULOS                                 */
  /* -------------------------------------------------------------------------- */

  activarTarifas: false,
  activarTrazabilidad: false,
  actualizarPrecioCoste: '',
  codigoArticuloAutomatico: false,
  crearArticuloDesdeEntrada:false,
  crearArticuloDesdeOT:false,
  descripcionArticuloLarga: false,
  precioConIVA: false,


  /* -------------------------------------------------------------------------- */
  /*                                  CLIENTES                                  */
  /* -------------------------------------------------------------------------- */

  activarClienteFijo: false,
  codigoClienteAutomatico: false,
  ordenComboClientes: "nombreFiscal",


  /* -------------------------------------------------------------------------- */
  /*                                   MODULOS                                  */
  /* -------------------------------------------------------------------------- */

  moduloAlmacen: false,
  moduloBar: false,
  moduloCaja: false,
  moduloCaja2: false,  //La caja 2 activará la serie 2 Tickets se usará para bares, peluquerías, etc.
  moduloCamping: false,
  moduloOcr: false,
  moduloPeluqueria: false,
  moduloTaller: false,
  moduloTienda: false,
  moduloVentas: false,
  // token: '',
  // usuario: '',


  /* -------------------------------------------------------------------------- */
  /*                      TICKETS FACTURAS PRESUPUESTOS Y ALBARANES             */
  /* -------------------------------------------------------------------------- */
  
  activarCajaEnFacturas: false,
  activarCobrosEnFacturas: false,
  activarCodigoBarrasEnTactil: false,
  activarDescuentosVolumen: false,
  activarDivisas: false,
  activarHistorialLineas: false,
  activarPrecioConIVAenFormularios: false,
  activarUnaSolaCaja: false,
  fechaLineaTicket: false,
  imprimirAlGrabar: false,
  iniciarCombosBlanco: false,
  nuevoAlGrabar: true,
  ocultarComboArticulos: false,
  permitirModificarFacturas: false,
  precioxTemporada: false,
  reportEnSerie: false, // Nos indica si el nombre del report está en la tabla serie en lugar de en la tabla empresa.
  siempreAbrirCajonAlGrabar: false,
  ticketSiCliente9999: false, // La factura cambiará a serie ticket automáticamente si el el cliente es 9999

  /* -------------------------------------------------------------------------- */
  /*                                  VEHÍCULOS                                 */
  /* -------------------------------------------------------------------------- */

  crearVehiculoDesdeOT:false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


